import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";


// Gets the logged in user data from local session
const getLoggedInUser = () => {
  return new Promise((resolve, reject) => {
    try {
      const user = localStorage.getItem("authUser");
      if (user) {
        const parsedUser = JSON.parse(user);
        resolve(parsedUser || null);
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const getCompanyId = () => {
  const user = localStorage.getItem("authUser");
  if (user) {
    const parsedUser = JSON.parse(user);
    return parsedUser?.company_id || null;
  }
  return null;
};


// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// company Register post(`${url.TASKTYPES}/update_tasktype`, tasktype);
export const companyRegisterAPI = data => post(`${url.COMPANY_REGISTER}`, data);  
export const validateAccountAPI = token => get(`${url.ACTIVATE_ACCOUNT}?token=${token}`);

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);


// Admin users page

//get contacts
//export const getUsers = () => get(url.GET_USERS);

export const getUsers = () =>{
  const companyId = getCompanyId();
  return get(`${url.GET_USERS}?company_id=${companyId}`);
}
// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
// export const updateUser = user => put(url.UPDATE_USER, user);
export const updateUser = user => post(url.UPDATE_USER, user);

// delete user
// export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });
// export const deleteUser = user => del(url.DELETE_USER, { user }, { headers: { 'Content-Type': 'application/json' } });
export const deleteUser = user => {
  const company_id = user.company_id;
  const user_id = user.user_id;  
  return del(url.DELETE_USER, {data: { company_id, user_id }}, { headers: { 'Content-Type': 'application/json' } })
  // return del(url.DELETE_USER, { user }, { headers: { 'Content-Type': 'application/json' } })
};

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get workflows
export const getWorkflowsApi = () => {
  const companyId = getCompanyId();
  return get(`${url.WORKFLOWS}?company_id=${companyId}`);
}
export const addNewWorkflowApi = workflow => post(`${url.WORKFLOWS}/create_workflow`, workflow);

export const updateWorkflow = workflow => post(`${url.WORKFLOWS}/update_workflow`, workflow);

export const getWorkflowConfig = id => get(`${url.WORKFLOWS}/get_workflow_config?config_id=${id}`)
export const getWorkflowTaskConfigApi = (workflow_id,task_id,tasktype_id) => get(`${url.WORKFLOWS}/get_workflow_task_config?worflow_id=${workflow_id}&task_id=${task_id}&tasktype_id=${tasktype_id}`)
export const updateWorkflowConfigApi = configdetails => post(`${url.WORKFLOWS}/update_workflow_config`, configdetails);
export const updateWfTaskConfigApi = task_config => post(`${url.WORKFLOWS}/save_workflow_task_config`, task_config);
export const deleteWorkflowConfigApi = configdetails => post(`${url.WORKFLOWS}/delete_workflow`, configdetails);
export const deleteWorkflowNodeConfig = deleteConfigdetails => post(`${url.WORKFLOWS}/delete_workflow_task`, deleteConfigdetails);


/**Task Types***/
export const getTaskTypesList = () => get(`${url.TASKTYPES}/get_tasktypes`);
export const getParentTaskTypes = () => get(`${url.TASKTYPES}/parent_tasktypes`);
export const addNewTaskType = tasktype => post(`${url.TASKTYPES}/create_tasktype`, tasktype);
export const updateTaskTypeAPI = tasktype => post(`${url.TASKTYPES}/update_tasktype`, tasktype);
export const fetchTaskTypeConfigListApi = (task_type_id) => get(`${url.TASKTYPES}/get_task_configuration?task_type_id=${task_type_id}`);
export const onAddNewConfigItemApi = configitem => post(`${url.TASKTYPES}/create_tasktype_field`, configitem);
export const onUpdateConfigItemApi = configitem => post(`${url.TASKTYPES}/update_tasktype_field`, configitem);
export const deletetasktypeApi = (task_type_id) => get(`${url.TASKTYPES}/delete_tasktype?task_type_id=${task_type_id}`);
export const deletetasktypefieldApi = (field_id) => get(`${url.TASKTYPES}/delete_tasktype_field?field_id=${field_id}`);

/**ForgotPassword */
export const requestForgotPasswordAPI = fp_request => post(`${url.FORGET_PASSWORD}`, fp_request);
export const requestVerifyOtpAPI = data => post(`${url.VERIFY_OTP_API}`, data);
export const requestResetPasswordAPI = data => post(`${url.RESET_PASSWORD}`, data);

/**Change Password */
export const changePasswordAPI = data => post(`${url.CHANGE_PASSWORD}`, data);

/**dashboard */
export const fetchDashboardDataApi = data => post(`${url.DASHBOARD_API}`, data);

/**File Manager */
export const getFiles = () => get(`${url.GET_FILES}`)
export const viewFileRequest = (bucketName,objectKey) => get(`${url.VIEW_FILE}?bucket_name=${bucketName}&object_key=${objectKey}`,{ responseType: 'blob' })

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile, 
};
