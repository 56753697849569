import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import authService from '../../../helpers/authService';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    });

    if (!response.IsError) {
      localStorage.setItem("authUser", JSON.stringify(response.Data));
      yield put(loginSuccess(response));
      history('/dashboard');
    } else {
      // Handle error if response is not valid
      const errorMessage = response.ErrorMessage || "Login failed. Please try again.";
      yield put(apiError(errorMessage));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* loginUser({ payload: { user, history } }){
//   try {
//     const data = yield call(authService.login, user.email, user.password);
//     localStorage.setItem("authUser", JSON.stringify(data.token));
//     yield put(loginSuccess(data));
//     history('/dashboard');
//   }
//   catch(error){
//     yield put(apiError(error));
//   }
// } 

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history("/dashboard");
      } else {
        history("/login");
      }
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    if(response)
    history("/dashboard");
  } catch (error) {
    console.log("error",error)
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
