import {
    GET_FILES_SUCCESS,
    GET_FILES_FAIL,
    VIEW_FILE_SUCCESS,
    VIEW_FILE_FAIL
} from "./actionTypes";

const INIT_STATE ={
    files: [],
    error: {},
    loading: false,
    //fileUrl: null,
    fileBlob: null
}

const filemanager = (state = INIT_STATE, action) => {
    switch(action.type){
        case GET_FILES_SUCCESS:
            return {
                ...state,
                files: action.payload.Data,
                loading:true
            };

        case GET_FILES_FAIL:
            return {
                ...state,
                error: action.payload
            }        
        case VIEW_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                //fileUrl: action.payload, 
                fileBlob: action.payload,            
            };
        case VIEW_FILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }

}

export default filemanager;
