import {
    GET_FILES,
    GET_FILES_SUCCESS,
    GET_FILES_FAIL,
    VIEW_FILE,
    VIEW_FILE_SUCCESS,
    VIEW_FILE_FAIL,
} from "./actionTypes";

export const getFiles = () =>({
    type: GET_FILES,
})

export const getFilesSuccess = files =>({
    type: GET_FILES_SUCCESS,
    payload: files,
})

export const getFilesFail = error =>({
    type: GET_FILES_FAIL,
    error: error,
})

// Action to initiate the file view request
export const viewFileRequest = (bucketName, objectKey) => ({    
    type: VIEW_FILE,
    payload: { bucketName, objectKey },
});

// Action on successful file fetch
// export const viewFileSuccess = fileUrl => ({
//     type: VIEW_FILE_SUCCESS,
//     payload: fileUrl,
// });
export const viewFileSuccess = fileBlob => ({
    type: VIEW_FILE_SUCCESS,
    payload: fileBlob,
});

// Action on file fetch failure
export const viewFileFail = error => ({
    type: VIEW_FILE_FAIL,
    payload: error,
});