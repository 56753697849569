import React, { useEffect, useMemo, useState } from "react";
import TableContainer from 'components/Common/TableContainer';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import FileViewer from 'react-file-viewer';
import { useSelector, useDispatch } from "react-redux";
import { getFiles as onGetFiles } from "store/actions";
import { isEmpty } from "lodash";
import { createSelector } from "reselect";

const FilesList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Use the navigate function
    const [viewFileModal, setViewFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const filemanagerProperties = createSelector(
        (state) => state.filemanager,
        (filemanager) => ({
            files: filemanager.files,
            loading: filemanager.loading
        })
    );

    const { files, loading } = useSelector(filemanagerProperties);
    console.log(files)
    // Fetch files on component mount
    useEffect(() => {
        if (isEmpty(files)) {
            dispatch(onGetFiles());
        }
    }, [dispatch, files]);

   
    // Handle file click and navigate to the file viewer page
    const handleViewFileClick = (file) => {
        // Navigate to the file viewer page with file information
        navigate(`/file-detail-viewer/${file.id}`, { state: { file } });
    };

    // Handle refresh click to reload files
    const handleRefreshClick = () => {
        dispatch(onGetFiles()); // Dispatch the action to refresh files
    };
    
    
    const columns = useMemo(
        () => [
            // {
            //     header: 'No',
            //     accessorKey: "id",
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     cell: (cellProps) => (
            //         <Link to="#" className="text-body fw-bold">
            //             {cellProps.row.original.id}
            //         </Link>
            //     ),
            // },
            {
                header: "File Name",
                accessorKey: "file_name",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Type",
                accessorKey: "file_type",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const fileType = cellProps.row.original.file_type;
                    const typeMapping = {
                        "pdf": { label: "PDF", color: "danger" },      // Red for PDF
                        "zip": { label: "ZIP", color: "secondary" },   // Grey for ZIP
                        "jpeg": { label: "Image", color: "primary" },  // Blue for JPEG
                        "text": { label: "Text", color: "info" },   // Green for Text
                        "jpg" : { label: "JPG", color: "primary" },    // Blue for JPG
                        "xlsx": { label: "XLSX", color: "success" },   
                        "csv" : { label: "CSV", color: "success" },       // Light blue for CSV
                    };
                    const typeInfo = typeMapping[fileType] || { label: "Other", color: "dark" }; // Default to "dark" if type not found
                    return (
                        // <span className={`badge badge-soft-${typeMapping[type] ? "info" : "warning"}`}>
                        //     {typeMapping[type] || "Other"}
                        // </span>
                        <span className={`badge badge-soft-${typeInfo.color}`}>
                            {typeInfo.label}
                        </span>
                    );
                },
            },
            {
                header: 'Uploaded Date',
                accessorKey: "event_time",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            <Button
                                size="sm"
                                className="btn-soft-primary"
                                onClick={() => handleViewFileClick(cellProps.row.original)}
                                id={`viewtooltip-${cellProps.row.original.id}`}
                            >
                                <i className="mdi mdi-eye-outline" />
                            </Button>
                            <UncontrolledTooltip
                                placement="top"
                                target={`viewtooltip-${cellProps.row.original.id}`}
                            >
                                View
                            </UncontrolledTooltip>
                        </li>
                    </ul>
                ),
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Files List" breadcrumbItem="My Files List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">File Lists</h5>
                                        <div className="flex-shrink-0">
                                            <Link  onClick={handleRefreshClick} className="btn btn-light me-1">
                                                <i className="mdi mdi-refresh" />
                                            </Link>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={files || []}
                                        // isJobListGlobalFilter={true}
                                        isGlobalFilter={true}
                                        isPagination={true}
                                        SearchPlaceholder="Search for ..."
                                        isCustomPageSize={true}
                                        tableClass="workflow-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        pagination="pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FilesList;
