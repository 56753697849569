import { call, put, takeEvery } from "redux-saga/effects"
import { GET_FILES, VIEW_FILE } from "./actionTypes"
import { 
    getFilesSuccess,
    getFilesFail,
    viewFileSuccess,
    viewFileFail
} from "./actions"
// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getFiles, viewFileRequest} from "helpers/fakebackend_helper";
import axios from 'axios';

function* fetchFiles() {
    try {
      const response = yield call(getFiles);
      console.log('respone:',response);
      if (!response.IsError) {
        yield put(getFilesSuccess(response))
      }
      else{
        yield put(getFilesFail(response.ErrorMessage))
      }
    } catch (error) {
      yield put(getFilesFail(error))
    }
  }  
  
//   function* onViewFile({ payload }) {
//     try {        
//       const { bucketName, objectKey } = payload; // Destructure bucketName and objectKey from payload
//       console.log(payload,bucketName, objectKey)
//       const response = yield call(viewFileRequest, bucketName, objectKey);
      
//       if (!response.IsError) {
//         const fileBlob = response.data; // Assuming the file data comes as a blob
//         console.log(fileBlob)
//         const url = window.URL.createObjectURL(new Blob([fileBlob])); // Convert to object URL
//         console.log(url)
//         yield put(viewFileSuccess(url)); // Dispatch success with the file URL
//     // Log response and its type to debug
//     console.log('API Response:', response);
//     console.log(response.data)
    
//     // if (response && response.data instanceof Blob) {
//     //     const fileBlob = response.data; // Get the blob data from response
//     //     const url = window.URL.createObjectURL(new Blob([fileBlob])); // Convert to object URL
//     //     console.log('Generated URL:', url);
//     //     // Dispatch success action with the generated URL
//     //     yield put(viewFileSuccess(url));
//       } else {
//         yield put(viewFileFail(response.ErrorMessage)); // Handle API error
//         console.error(response.ErrorMessage); // Optionally log the error
//       }
//     } catch (error) {
//       yield put(viewFileFail(error)); // Handle general errors
//       console.error("Error viewing file:", error);
//     }
//   }

// function* onViewFile({ payload }) {
//     try {        
//         const { bucketName, objectKey } = payload;
//         const response = yield call(viewFileRequest, bucketName, objectKey);

//         if (response && response.data instanceof Blob) {
//             // Pass the raw Blob to Redux (not a URL)
//             yield put(viewFileSuccess(response.data));
//         } else {
//             yield put(viewFileFail(response.ErrorMessage));
//         }
//     } catch (error) {
//         yield put(viewFileFail(response.ErrorMessage));
//         console.error("Error viewing file:", error);
//     }
// }
// function* onViewFile({ payload }) {
//     try {        
//         const { bucketName, objectKey } = payload;
//         const response = yield call(viewFileRequest, bucketName, objectKey);

//         // Check if the response contains a valid Blob
//         if (response && response instanceof Blob) {
//             // Pass the raw Blob to Redux (not a URL)
//             yield put(viewFileSuccess(response));
//         } else if (response && response.data instanceof Blob) {
//             // Some APIs return the blob inside a 'data' object
//             yield put(viewFileSuccess(response.data));
//         } else {
//             // If no Blob or invalid response, handle failure
//             yield put(viewFileFail('Invalid file format or response'));
//         }
//     } catch (error) {
//         yield put(viewFileFail(error.message || 'Error fetching file'));
//         console.error("Error viewing file:", error);
//     }
// }

function* onViewFile({ payload }) {
    try {
        const { bucketName, objectKey } = payload;
        const response = yield call(viewFileRequest, bucketName, objectKey);

        if (response && response instanceof Blob) { // Ensure it's a Blob
            yield put(viewFileSuccess(response));
        } else {
            yield put(viewFileFail('Invalid Blob'));
        }
    } catch (error) {
        yield put(viewFileFail(error));
        console.error("Error viewing file:", error);
    }
}

  function* filemanagerSaga() {
    yield takeEvery(GET_FILES, fetchFiles)
    yield takeEvery(VIEW_FILE, onViewFile)
  }

  export default filemanagerSaga;
